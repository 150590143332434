<template>
  <div>
    <b-card
      v-if="barcodeData !== null && barcodeData.reportsPublished === false && typeof barcodeData.reUploadedReports !== 'undefined'"
      :title="
        `View Unpublished Reports for ${barcodeData.corBarcode}/${barcodeData.stBarcode}`
      "
    >
      <main>
        <b-card
          v-for="(value, propertyName, index) in barcodeData.reUploadedReports"
          :key="index"
          :title="TitleCase(propertyName)"
          class="mx-1"
        >
          <div class="d-flex flex-row align-items-center justify-content-between">
            {{ barcodeData.corBarcode || barcodeData.stBarcode }}_{{ propertyName }}.pdf
            <div
              v-if="typeof barcodeData.reports !== 'undefined'"
              class="d-flex align-items-center justify-content-center"
            >

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="handleDownload(propertyName, 'unpublished')"
              >
                Download
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-1"
                variant="outline-primary"
                @click="handleViewPDF(propertyName, 'unpublished')"
              >
                View
              </b-button>
            </div>
          </div>
          <span
            v-if="valid.val && valid.index === 0"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
      </main>
      <div class="d-flex justify-content-end w-100">
        <b-button
          v-if="barcodeData.reports.starterNutritionPlan && !barcodeData.nutritionPlanPublished"
          variant="primary"
          class="mx-1"
          @click="handlePublishStarter"
        >
          <span v-if="loading !== true">Publish Starter Nutrition Plan</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
        <b-button
          v-if="reportsStatus"
          variant="primary"
          @click="handlePublish"
        >
          <span v-if="loading !== true">Publish</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
      </div>
      <b-modal
        id="modal-xl"
        v-model="showModal"
        ok-only
        ok-title="Close"
        centered
        size="xl"
        :title="currentTitle"
        @hide="handleHide"
      >
        <b-card-text class="h-100 w-100">
          <div
            v-if="loading"
            class="d-flex w-100 align-items-center justify-content-center my-5"
          >
            <b-spinner label="Loading..." />
          </div>
          <object
            v-else
            :data="pdfSrc"
            style="width: 100%; height: 100%"
            type="application/pdf"
          />
        </b-card-text>
      </b-modal>
    </b-card>
    <b-card
      v-if="barcodeData !== null"
      :title="
        `View ${
          barcodeData.reportsPublished === false && typeof barcodeData.reUploadedReports === 'undefined' ? 'Unpublished' : 'Previously Published'
        } Reports for ${barcodeData.corBarcode}/${barcodeData.stBarcode}`
      "
    >
      <main>
        <b-card
          v-for="(value, propertyName, index) in barcodeData.reports"
          :key="index"
          :title="TitleCase(propertyName)"
          class="mx-1"
        >
          <div class="d-flex flex-row align-items-center justify-content-between">
            {{ barcodeData.corBarcode || barcodeData.stBarcode }}_{{ propertyName }}.pdf
            <div
              v-if="typeof barcodeData.reports !== 'undefined'"
              class="d-flex align-items-center justify-content-center"
            >

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="handleDownload(propertyName)"
              >
                Download
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="ml-1"
                variant="outline-primary"
                @click="handleViewPDF(propertyName)"
              >
                View
              </b-button>
            </div>
          </div>
          <span
            v-if="valid.val && valid.index === 0"
            class="text-danger font-weight-bolder my-1"
          >{{ valid.message }}</span>
        </b-card>
      </main>
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="primary"
          @click="$router.push({name: 'upload-covid-report', params: {id: barcodeData._id}})"
        >
          Upload Reports
        </b-button>
        <b-button
          v-if="reportsStatus && barcodeData.reportsPublished !== true"
          variant="primary"
          class="ml-2"
          @click="handlePublish"
        >
          <span v-if="loading !== true">Publish</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
        <b-button
          v-else-if="typeof barcodeData.reports.starterNutritionPlan !== 'undefined' && barcodeData.nutritionPlanPublished !== true"
          variant="primary"
          class="ml-2"
          @click="handlePublishStarter"
        >
          <span v-if="loading !== true">Publish Starter Nutrition Plan</span>
          <b-spinner
            v-else
            variant="white"
          />
        </b-button>
      </div>
      <b-modal
        id="modal-xl"
        v-model="showModal"
        ok-only
        ok-title="Close"
        centered
        size="xl"
        :title="currentTitle"
        @hide="handleHide"
      >
        <b-card-text class="h-100 w-100">
          <div
            v-if="loading"
            class="d-flex w-100 align-items-center justify-content-center my-5"
          >
            <b-spinner label="Loading..." />
          </div>
          <object
            v-else
            :data="pdfSrc"
            style="width: 100%; height: 100%"
            type="application/pdf"
          />
        </b-card-text>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import Ripple from 'vue-ripple-directive'
/* eslint-disable no-return-assign */
export default {
  directives: { Ripple },
  data() {
    return {
      viewPdf: null,
      id: null,
      barcodeData: null,
      healthReportFile: null,
      recommendationReportFile: null,
      wellnessReportFile: null,
      summaryReportFile: null,
      valid: {
        val: false,
        index: null,
        message: 'Incorrect filename',
      },
      showModal: false,
      pdfSrc: null,
      loading: null,
      currentTitle: null,
      reports: {
        published: {
        },
        unpublished: {
        },
      },
    }
  },
  computed: {
    // returns whether all the reports are uploaded or not
    reportsStatus() {
      return this.barcodeData.reportsPublished === false ? (typeof this.barcodeData.reports.gutMicrobiomeReport !== 'undefined'
        || typeof this.barcodeData.reports.gutRestorerPlan !== 'undefined') : typeof this.barcodeData.reUploadedReports !== 'undefined'
    },
  },
  beforeMount() {
    this.id = this.$route.params.id
    this.$http
      .get(`/barcodes/${this.id}`)
      .then(({ data }) => (this.barcodeData = data))
      .catch(this.handleError)
  },
  methods: {
    TitleCase(s) {
      return s.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
        .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`)
    },
    Capitalize(str) {
      return str.trim().replace(/^\w/, c => c.toUpperCase())
    },
    getBlob(link) {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'GET',
          url: link,
          responseType: 'blob',
        }).then(resp => {
          resolve(window.URL.createObjectURL(resp.data))
        }).catch(err => {
          reject(err)
          this.handleError(err)
        })
          .finally(() => {
            this.loading = false
          })
      })
    },
    async handleDownload(reportType, type = 'published') {
      let url
      if (typeof this.reports[type][reportType] !== 'undefined') {
        url = this.reports[type][reportType]
      } else {
        this.reports[type][reportType] = await this.getBlob(type === 'published' ? this.barcodeData.reports[reportType] : this.barcodeData.reUploadedReports[reportType])
        url = this.reports[type][reportType]
      }
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.barcodeData.corBarcode || this.barcodeData.stBarcode}_${reportType}.pdf`) // or any other extension
      document.body.appendChild(link)
      link.click()
    },
    handlePublish() {
      this.loading = true
      if (this.reportsStatus) {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
        this.$http.post(`/barcodes/covid/${this.barcodeData._id}/publish`).then(() => {
          this.$bvToast.toast(`Reports published successfully for ${this.barcodeData.corBarcode}`, {
            title: 'Successful',
            variant: 'success',
            solid: true,
          })
          this.$router.push({ name: 'list-registered-barcodes' })
        }).catch(this.handleError).finally(() => {
          this.loading = false
        })
      } else {
        this.$bvToast.toast('Reports missing', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    handlePublishStarter() {
      this.loading = true
      if (this.barcodeData.reports.starterNutritionPlan) {
      /* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
        this.$http.post(`/barcodes/covid/${this.barcodeData._id}/nutrition/publish`).then(() => {
          this.$bvToast.toast(`Starter Nutrition Plan published successfully for ${this.barcodeData.corBarcode}`, {
            title: 'Successful',
            variant: 'success',
            solid: true,
          })
          this.barcodeData.nutritionPlanPublished = true
        }).catch(this.handleError).finally(() => {
          this.loading = false
        })
      } else {
        this.$bvToast.toast('Reports missing', {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    handleHide() {
      this.currentTitle = null
      this.pdfSrc = null
    },
    async handleViewPDF(reportType, type = 'published') {
      this.showModal = true
      this.loading = true
      this.currentTitle = `${this.Capitalize(reportType)} Report`
      if (typeof this.reports[type][reportType] !== 'undefined') {
        this.pdfSrc = this.reports[type][reportType]
        this.loading = false
      } else {
        this.reports[type][reportType] = await this.getBlob(type === 'published' ? this.barcodeData.reports[reportType] : this.barcodeData.reUploadedReports[reportType])
        this.pdfSrc = this.reports[type][reportType]
      }
      // fetch(this.barcodeData.reports[reportType])
      //   .then(resp => resp.blob())
      //   .then(blob => {
      //     this.pdfSrc = window.URL.createObjectURL(blob)
      //   })
      //   .catch(this.handleError)
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
  },
}
</script>

<style lang="scss" scoped>
main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr 1fr;
}
</style>
<style lang="scss">

.modal .modal-content {
  height: 90vh;
}
</style>
